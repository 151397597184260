import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import profileReducer from './profileReducer';

const appReducer = combineReducers({
  profile: profileReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT_REQUEST') {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
