import React, { useState, useEffect, lazy } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { JWT_ACCESS_TOKEN_KEY } from 'lib/network/jwt-token-access';
import Loadable from 'layouts/full/shared/loadable/Loadable';
import TopBarProgress from "react-topbar-progress-indicator"

const FullLayout = Loadable(lazy(() => import('layouts/full/FullLayout')));

TopBarProgress.config({
  barColors: {
      "0": "#5D87FF",
      "1.0": "#5D87FF",
  },
  barThickness: '5',
  className: "topbar"
});

const AppRoute = () => {
  const [progress, setProgress] = useState(false)
   const [prevLoc, setPrevLoc] = useState("")
  const token = localStorage.getItem(JWT_ACCESS_TOKEN_KEY);
  const location = useLocation();

  useEffect(() => {
    setPrevLoc(location.pathname)
    setProgress(true)
    if(location.pathname===prevLoc){
        setPrevLoc('')
    }
 }, [location])

  useEffect(() => {
    setProgress(false)
  }, [prevLoc])

  return (
    <>
      {progress && <TopBarProgress />}
      {token ? (
        <FullLayout>
          <Outlet />
        </FullLayout>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default AppRoute;
