import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const contextClass = {
  success: 'bg-green-600',
  error: 'bg-red-600',
  info: 'bg-blue-600',
  warning: 'bg-orange-400',
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300',
};

export function ToastComponent() {
  return (
    <div>
      <ToastContainer
        toastClassName={({ type }) =>
          contextClass[type || 'default'] +
          ' relative flex text-sm min-h-10 rounded-md justify-center overflow-hidden'
        }
        bodyClassName={() => 'text-xs text-center font-white font-medium block p-3'}
        position="top-right"
        autoClose={2000}
        icon={false}
        closeButton={false}
      />
    </div>
  );
}
