export const JWT_ACCESS_TOKEN_KEY = 'JWT_ACCESS_TOKEN_KEY';
export const JWT_REFRESH_TOKEN_KEY = 'JWT_REFRESH_TOKEN_KEY';

export default function getAccessToken() {
  if (typeof window !== 'undefined') {
    const accessToken = localStorage.getItem(JWT_ACCESS_TOKEN_KEY);
    if (accessToken) {
      return accessToken;
    } else {
      return {};
    }
  }
}

export function storeAccessToken(accessToken, refreshToken) {
  localStorage.setItem(JWT_ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(JWT_REFRESH_TOKEN_KEY, refreshToken);
}

export function storeRemoveAccessToken() {
  localStorage.removeItem(JWT_ACCESS_TOKEN_KEY);
  localStorage.removeItem(JWT_REFRESH_TOKEN_KEY);
}
